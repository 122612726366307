@import "ngx-toastr/toastr";
@import "variables";
@import "scss/fonts";
@import "scss/layout-grid";
@import "scss/material-overrides/mat-button-overrides";
@import "scss/material-overrides/mat-checkbox-overrides";
@import "scss/material-overrides/mat-card-overrides";
@import "scss/material-overrides/mat-select-overrides";
@import "scss/material-overrides/mat-sidenav-overrides";
@import "scss/material-overrides/mat-slider-overrides";
@import "scss/material-overrides/mat-radio-button-overrides";
@import "scss/material-overrides/typography";
@import "scss/material-symbols";
@import "scss/theming";
@import "scss/toaster";
@import "../../alcedo/src/scss/outdated";

html {
  background-color: white;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  font-family: $font-family;
}

input {
  -webkit-tap-highlight-color: transparent; // Remove tap color from Mobile Safari

  &:focus-visible {
    outline: 0;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

cg-busy .custom-busy-template {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: transparent;
}

.back-button {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  text-align: start;
  padding: 0;
  font:
    400 24px/32px Quicksand,
    Helvetica Neue,
    sans-serif;
  letter-spacing: normal;
  line-height: normal;
  font-size: 2em;
  background: transparent;
  border: 0;
  cursor: pointer;
  color: #fff;
}

@media (max-width: 600px) {
  .back-button {
    font-size: 1.4em;
  }
}

// Device registration wizard
app-alerting-ask,
app-alerting-choice,
app-alerting-complete,
app-alerting-configure,
app-alerting-copy,
app-alerting-no-alarm-chain,
app-confirm-client,
app-device-change-name,
app-device-real-estate-add,
app-device-real-estate-ask,
app-device-real-estate-select,
app-login,
app-no-account-info,
app-replace-device,
app-replace-device-confirm,
app-reset-password,
app-sign-up-ask,
app-sign-up-business,
app-sign-up-complete,
app-sign-up-confirm,
app-sign-up-confirm-activation,
app-sign-up-private,
app-sign-up-resend-email {
  background: rgb(129, 212, 250);
  background: radial-gradient(circle, rgba(129, 212, 250, 1) 0%, rgba(3, 155, 229, 1) 100%);
  color: white;
  display: grid;
  font-family: $font-family;
  height: calc(100vh - #{$base-size * 8});
  left: 0;
  overflow-y: auto;
  position: absolute;
  top: 0;
  transition: left 0.3s $default-easing;
  width: 100%;

  --mdc-icon-button-icon-color: white;

  alc-message mat-icon {
    color: white !important;
  }
}

// Device registration wizard
app-alerting-ask,
app-alerting-choice,
app-alerting-complete,
app-alerting-configure,
app-alerting-copy,
app-alerting-no-alarm-chain,
app-confirm-client,
app-device-change-name,
app-device-real-estate-add,
app-device-real-estate-ask,
app-device-real-estate-select,
app-license,
app-login,
app-no-account-info,
app-replace-device,
app-replace-device-confirm,
app-reset-password,
app-sign-up-ask,
app-sign-up-business,
app-sign-up-complete,
app-sign-up-confirm,
app-sign-up-confirm-activation,
app-sign-up-private,
app-sign-up-resend-email {
  h2.mat-headline-6,
  h3 {
    font-family: $font-family;
    font-size: 2em;
    font-weight: 200;
    margin-bottom: 0.5em;
    text-align: center;
  }

  .center-container {
    align-content: center;
    display: grid;
    height: 100%;
    box-sizing: border-box;
    padding: 5vh 0 20vh 0;
    max-width: 600px;
    width: 50vw;
    margin: 0 auto;
    text-align: center;

    .action-buttons {
      display: grid;
      grid-gap: 2 * $base-gap;

      &.ok-cancel {
        grid-template-areas: "cancel . ok";
        grid-template-columns: min-content auto min-content;
      }

      a {
        height: $base-height;
        line-height: $base-height;
      }
    }
  }

  .mat-mdc-unelevated-button {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: bold;
    min-width: 120px;

    &.mat-primary:not(:disabled) {
      background-color: $secondary-color;
      color: white;
    }

    &.cancel {
      grid-area: cancel;
    }

    &.ok {
      grid-area: ok;
    }

    &.warning {
      background-color: #b70023;
    }
  }

  mat-form-field {
    width: 100%;
  }

  a:link,
  a:visited,
  a:active {
    color: $secondary-color;
    font-weight: bold;
  }
}

@media (max-width: 600px) {
  app-alerting-ask,
  app-alerting-choice,
  app-alerting-complete,
  app-alerting-configure,
  app-alerting-copy,
  app-alerting-no-alarm-chain,
  app-confirm-client,
  app-device-change-name,
  app-device-real-estate-add,
  app-device-real-estate-ask,
  app-device-real-estate-select,
  app-login,
  app-no-account-info,
  app-replace-device,
  app-replace-device-confirm,
  app-reset-password,
  app-sign-up-ask,
  app-sign-up-business,
  app-sign-up-complete,
  app-sign-up-confirm,
  app-sign-up-confirm-activation,
  app-sign-up-private,
  app-sign-up-resend-email {
    height: calc(100vh - #{$base-size * 9});

    .center-container {
      width: 80vw;
    }
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  app-alerting-ask,
  app-alerting-choice,
  app-alerting-complete,
  app-alerting-configure,
  app-alerting-copy,
  app-alerting-no-alarm-chain,
  app-confirm-client,
  app-device-change-name,
  app-device-real-estate-add,
  app-device-real-estate-ask,
  app-device-real-estate-select,
  app-login,
  app-no-account-info,
  app-replace-device,
  app-replace-device-confirm,
  app-reset-password,
  app-sign-up-ask,
  app-sign-up-business,
  app-sign-up-complete,
  app-sign-up-confirm,
  app-sign-up-confirm-activation,
  app-sign-up-private,
  app-sign-up-resend-email {
    .center-container {
      padding: 5vh 2vw 20vh 2vw;
      width: 60vw;
    }
  }
}

// Flexbox
.flex {
  flex: 1;
  box-sizing: border-box;
}

.flex-50 {
  flex: 1 1 100%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box;
}

.hidden,
.display-none {
  display: none !important;
}

.layout,
.layout-column,
.layout-row {
  box-sizing: border-box;
  display: flex;
}

.layout-row {
  flex-direction: row;

  > .flex {
    min-width: 0;
  }

  > .flex-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
}

.layout-column {
  flex-direction: column;

  > .flex {
    min-height: 0;
  }

  > .flex-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
}

.layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.layout-align-center-start,
.layout-align-end-start,
.layout-align-space-around-start,
.layout-align-space-between-start,
.layout-align-start-start {
  align-items: flex-start;
  align-content: flex-start;
}

.layout-align-center-center,
.layout-align-end-center,
.layout-align-space-around-center,
.layout-align-space-between-center,
.layout-align-start-center {
  align-items: center;
  align-content: center;
  max-width: 100%;

  > * {
    max-width: 100%;
    box-sizing: border-box;
  }
}

.layout-align-center-end,
.layout-align-end-end,
.layout-align-space-around-end,
.layout-align-space-between-end,
.layout-align-start-end {
  align-items: flex-end;
  align-content: flex-end;
}

.layout-align-start,
.layout-align-start-center,
.layout-align-start-end,
.layout-align-start-start,
.layout-align-start-stretch {
  justify-content: flex-start;
}

.layout-align-end,
.layout-align-end-center,
.layout-align-end-end,
.layout-align-end-start,
.layout-align-end-stretch {
  justify-content: flex-end;
}

.layout-align-center,
.layout-align-center-center,
.layout-align-center-end,
.layout-align-center-start,
.layout-align-center-stretch {
  justify-content: center;
}

@media screen and (min-width: 600px) {
  .layout-row-gt-xs {
    flex-direction: row;
  }
  .layout-row,
  .layout-row-gt-xs {
    .flex-50-gt-xs {
      flex: 1 1 100%;
      max-width: 50%;
      max-height: 100%;
      box-sizing: border-box;
    }
  }
}
